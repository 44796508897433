import React from 'react';
import axios from 'axios';
import { Message, Dimmer, Loader } from 'semantic-ui-react';
import RSVPSearch from './RSVPSearch';
import RSVPRegistration from './RSVPRegistration';

class RSVP extends React.Component {
    state = {
        keyword: '',
        res: [],
        error: null,
        isResponseCompleted: false,
        message:''
    };

    onSearchSubmit = async (qry) => {
        this.setState({
            keyword: qry,
            res: [],
            isResponseCompleted: false
        });

        await axios.get(`${process.env.REACT_APP_BASE_URL}/invitations`, {
            params: {
                search: qry
            }
        })
        .then(res => {
            const invitations = res.data;
            var errMessage = "";
            if(res.data.message)
            {
                errMessage = res.data.message;
            }

            this.setState({ 
                error: null,
                res: invitations,
                isResponseCompleted: true,
                message: errMessage
                });
        })
        .catch((error) => {
            console.log(`${process.env.REACT_APP_BASE_URL}/invitations`);
            console.log(error);
            this.setState({
                error: error,
                res: [],
                isResponseCompleted: true 
            })
        });

    }

    showResultList() {
        if (this.state.error){
            return(<Message error><Message.Header>Error: {this.state.error.code}</Message.Header>
            {this.state.error.message} {this.state.error.response?.data?.message}
            </Message>);
        }
        if (this.state.message){
            return(
            <Message negative>
                <Message.Header>No records found</Message.Header>
                Your search for <b>{this.state.keyword}</b> produced no results. Please try another keyword.
            </Message>
            );
        }
        else {
            if (this.state.keyword === '') {
                return (<div className="ui container"> Please type a keyword and press Enter to search </div>);
            }
            else {
                if (!this.state.isResponseCompleted) {
                    return (
                        <Dimmer active>
                            <Loader content='Loading' />
                        </Dimmer>
                    );
                }
                // else if (this.state.message && this.state.res.length === 0 && this.state.isResponseCompleted) {
                //         return (
                //             <Message negative>
                //                 <Message.Header>No records found</Message.Header>
                //                 Your search for <b>{this.state.keyword}</b> produced no results. Please try another keyword.
                //             </Message>
                //         );
                // }
                else if (this.state.res.length > 0 && this.state.isResponseCompleted) {
                    return (
                        <RSVPRegistration data={this.state.res} />
                    )
                }
            }
        }
    }



    render() {
        return (
            <section id="rsvpFormPage" className="rsvp-main" style={{ border: '0px Solid Orange' }}>
                <a style={{top:'5px', left: '5px', fontSize: '2em', fontWeight: 'bold', color: '#e7a49b',  position:'absolute'}} href="/">Back</a>
                <div className="rsvp-header">
                    <div className="love-header">
                        RSVP Registration
                    
                    </div>
                </div>

                <RSVPSearch onSubmit={this.onSearchSubmit}></RSVPSearch>
                {this.showResultList()}

            </section>
        );
    }
}
 
export default RSVP;