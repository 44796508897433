import React from 'react';
//import { Message, Dimmer, Loader } from 'semantic-ui-react';
import '../css/fonts.css';
import '../css/rsvp.css'
 
class LinkToRSVP extends React.Component {
    render() {
        return (
            <section id="rsvp" className="rsvp-main" style={{ border: '0px Solid Orange' }}>
                <div className="rsvp-header">
                <div className="love-header">
                    Are You Attendin&#xE006;
                </div>
                </div>
                <a href="rsvp"> 
                <div className="rsvp-outer" style={{ border: '0px Solid Pink' }}>
                    <div className="rsvp-text">
                    RSVP now
                         
                    </div>
                </div>
                </a>
            </section>
        );
    }
} 

export default LinkToRSVP;