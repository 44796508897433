import React from 'react';
import { Button } from 'semantic-ui-react';
import '../css/placeandtime.css';
 
class PlaceAndTime extends React.Component {
 
    constructor(props) {
        super(props);
        this.state = {
            showCeremonyMap: false,
            showReceptionMap: false
        }

        console.log("Ceremony:",this.state.showCeremonyMap);
        console.log("Reception:", this.state.showReceptionMap);
    }
    
    toggleCeremonyMap = () => {
        this.setState({showCeremonyMap:!this.state.showCeremonyMap, showReceptionMap:false});
        console.log(this.state.showCeremonyMap);
    };

    toggleReceptionMap = () => {
        this.setState({showReceptionMap:!this.state.showReceptionMap, showCeremonyMap:false});
        console.log(this.state.showReceptionMap);
    };
     
    
    render() {
        return (
            <section id="placeandtime" className="pt-main" style={{ border: '0px Solid Orange' }}>
                <div className="pt-love-header">
                    Place & Time
                </div>
                <div className="pt-body">
                    <div className="pt-card pt-ceremony-background">
                        <div className="pt-white-card">
                            <div className="pt-brideandgroom-icon">&nbsp;</div>
                            <p className="pt-card-header">
                                The Ceremony
                            </p>
                            <p className="pt-card-content">
                                Saturday, June 14, 2025 
                                <br/>
                                11:30 PM - 1:00 PM <br/><br/><br/>
                                <p className="pt-card-venuename">St Clement's Church</p>
                                70 St. Clements Ave.<br/>
                                Toronto, ON<br/>
                                M5R 1H2
                                <br/><br/><br/>
                               
                                <Button style={{ 
                                            backgroundColor: '#ffffff',
                                            border: '2px Solid #e7a49b',
                                            width:'150px',
                                            height:'60px',
                                            // lineHeight:'40px',
                                            textDecoration:'none',
                                            color:'#e7a49b',
                                            padding:'20px 30px 20px 30px'
                                }} onClick={this.toggleCeremonyMap}> 
                                {this.state.showCeremonyMap ? "Hide Map" : "Show Map"}
                                </Button>
                            </p>
                           
                        </div>
                    </div>
                    <div className="pt-card pt-reception-background">
                        <div className="pt-white-card">
                            <div className="pt-cake-icon"> &nbsp;</div>
                            <p className="pt-card-header">
                                The Reception
                            </p>
                            <p className="pt-card-content">
                                Saturday, June 14, 2025<br/>
                                5:30 PM - 1:30 AM <br/><br/><br/>
                                <p className="pt-card-venuename">Millennium Gardens Banquet Centre</p>
                                20 Polonia Ave., #100<br/>
                                Brampton, ON<br/>
                                L6Y 0K9
                                <br/><br/><br/>
                                <Button style={{ 
                                            backgroundColor: '#ffffff',
                                            border: '2px Solid #e7a49b',
                                            width:'150px',
                                            height:'60px',
                                            textDecoration:'none',
                                            color:'#e7a49b',
                                            padding:'20px 30px 20px 30px'
                                }} onClick={this.toggleReceptionMap}> 
                                {this.state.showReceptionMap ? "Hide Map" : "Show Map"}
                                </Button>
                            </p>
                        </div>

                    </div>
                </div>

                {this.state.showCeremonyMap && (
                <div style={{ marginTop: "20px" }}>
                    <iframe id="ifrCeremony" title="Ceremony Map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2594.2771996508604!2d-79.40073168394431!3d43.71300957350428!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b332221c2c5a9%3A0xa6ddc4fd20c6cf07!2sSt%20Clement&#39;s%20Church!5e0!3m2!1sen!2sca!4v1736557373808!5m2!1sen!2sca"
                    width="100%"
                    height="400"
                    style={{ border: "0" }}
                    allowfullscreen="" 
                    loading="lazy">
                    </iframe>
                </div>
                 )}

                 {this.state.showReceptionMap && (
                <div style={{ marginTop: "20px" }}>
                    <iframe id="ifrReception" title="Reception Map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4808.012602547011!2d-79.76166802864317!3d43.64161565074664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b153629dbcf9b%3A0x7e28c6a4472c553a!2sMillennium%20Gardens%20Banquet%20Centre!5e0!3m2!1sen!2sca!4v1736557164082!5m2!1sen!2sca" 
                    width="100%"
                    height="400"
                    style={{ border: "0" }}
                    allowfullscreen="" 
                    loading="lazy">
                    </iframe>
                </div>
                )}
            </section>
          ); 
    }
}

export default PlaceAndTime;